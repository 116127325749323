<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card flat>
                            <v-card-text>
                                <v-form ref="form" v-model="valid">
                                    <v-text-field
                                        outline clearable
                                        prepend-icon="email"
                                        name="email"
                                        :label="$t('changeEmail.email')"
                                        type="email"
                                        v-model="email"
                                        :rules="rules"
                                    ></v-text-field> 
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn large block class="subheading" color="primary" :disabled="btnDisabled" @click="submit">{{ btnName }}</v-btn>
                            </v-card-actions>
                        </v-card>
                        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
                <confirm-auth
                    :user-id="userId"
                    :email="email"
                    @pass="passAuth"
                    @back="setStepNo(1)"
                ></confirm-auth>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import ConfirmAuth from '@/components/ConfirmAuth'
import WaitingDialog from '@/components/WaitingDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import UserMixin from '@/mixins/UserMixin'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { UserStatus } from '@/mixins/UserMixin'
import i18n from '@/i18n'

export default {
    name: 'ChangeName',
    components: {
        ConfirmAuth,
        WaitingDialog
    },
    mixins: [ WaitDialogControl, UserMixin ],
    data() {
        return {
            titles: [`${i18n.t('changeEmail.nav-change-email')}`, i18n.t('changeEmail.verify-email')],
            stepNo: 1,
            valid: false,
            userId: store.getters.user.id,
            email: '',
            rules: [
                v => !!v || i18n.t('changeEmail.rules.emptyErr'),
                v => /.+@.+/.test(v) || i18n.t('changeEmail.rules.validErr')
            ]
        }
    },
    computed: {
        btnName () {
            return this.checkUserStatus(UserStatus.EmailVerified) ? i18n.t('changeEmail.change') : i18n.t('changeEmail.re-send-letter')
        },
        btnDisabled () {
            return !this.valid || (this.email === store.getters.user.email && this.checkUserStatus(UserStatus.EmailVerified))
        },
        isSocialLogin() {
            return store.getters.user.status && ((store.getters.user.status & 1 << 5) === 1 << 5)
        }
    },
    methods: {
        init() {
            this.email = store.getters.user.email
            this.setStepNo(1)
        },
        setStepNo(no) {
            this.stepNo = no
            store.commit('setTitle', this.titles[this.stepNo - 1])
            this.closeDialog()
        },
        passAuth() {
            store.commit('setSnackBar', {
                message: i18n.t('changeEmail.change-success'),
                color: 'success'
            })
            this.$router.back()
        },
        async submit() {
            if (this.$refs.form.validate()) {
                this.showDialog(i18n.t('changeEmail.update-info'), i18n.t('changeEmail.update-fail'))
                try {
                    let suburl = '/api/user/profile';
                    let response = await axios.patch(HostUrl + suburl, {
                        email: this.email
                    }, getAxiosConfig())
                    if (response.data.code === 0) {
                        store.commit('setSnackBar', {
                            message: i18n.t('changeEmail.auth-link'),
                            color: 'success'
                        })
                        this.setStepNo(2)
                        // 舊email成功處理
                        // store.commit('setUserProfile', response.data.profile)
                        // this.$router.back()
                    } else this.showMsgDialog(response.data.message, i18n.t('changeEmail.close'))
                } catch (error) {
                    this.showMsgDialog(i18n.t('changeEmail.update-info-fail'), i18n.t('changeEmail.close'))
                } finally {
                    this.closeDialog()
                }
            }
        }
    },
    activated() {
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
    }
}
</script>

<style scoped>
</style>
